<template>
<div class="comissiontixian">
  <van-nav-bar
    title="提现"
    left-arrow
    @click-left="onClickLeft"
  />
  <div class="tixian_main">
    <!-- <van-cell class="account_name" :border="false">
      <template #default>
        提现到账户绑定微信号钱包余额
      </template>
    </van-cell> -->
    <van-form @submit="onSubmit">
      <van-cell :border="false">
        <template #title>真实姓名</template>
      </van-cell>
      <van-field
        v-model="realname"
        placeholder="请输入微信绑定的真实姓名"
        :rules="[{ required: true, message: '请输入微信绑定的真实姓名' }]"
      />
      <van-cell :border="false">
        <template #title>提现</template>
      </van-cell>
      <van-field
        v-model="money"
        label="￥"
        type="number"
        readonly
        placeholder="提现金额"
        :rules="[{ required: true, message: '请输入提现金额' }]"
      />
      <van-cell class="tixian_label" :border="false">
        <template #title>可提现金额 &yen;{{money}}</template>
      </van-cell>
      <div style="margin: 16px;">
        <van-button
          round
          block
          type="danger"
          native-type="submit"
          :loading="load"
        >
          提现
        </van-button>
      </div>
    </van-form>
  </div>
  
</div>
</template>
<script>
export default {
  name: "ComissionTixian",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      money: 0,
      minMoney: 20, // 后台设置的最小提现金额
      shareid: 0,
      merchid: 0,
      userinfo: {},
      wxinfo: {},
      load: false,
      realname: '', // 真实姓名
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "提现");
    this.init();
    if (this.$route.query.code) {
      this.getwxinfo(this.$route.query.code);
    }
  },
  methods: {
    init() {
      // 获取可提现金额 和提现金额阈值
      this.getTxcomission();
      this.getinfor();
    },
    getTxcomission() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/commission/get_tixianSet")
        .then(response => {
          console.log(response);
          if (response.data.code == 100000) {
            _that.minMoney = response.data.data.minMoney && parseFloat(response.data.data.minMoney) > 0 ? parseFloat(response.data.data.minMoney) : 0;
            _that.money = parseFloat(response.data.data.money);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    getinfor() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + 'web/user/getinfor')
        .then(function(response){
          _that.userinfo = response.data;
          console.log(_that.userinfo, "userinfo");
        })
        .catch(function(error){
          console.log(error);
        })
    },
    onSubmit() {
      console.log("ssss");
      // 分情况 1。金额小于50元，
      let _that = null;
      _that = this;
      if (parseFloat(this.money) <= parseFloat(this.minMoney)) {
        _that.$toast("提现金额不能少于" + this.minMoney + "元")
        return false;
      }
      this.load = true;
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");

      _that.$dialog.alert({
        showCancelButton: true,
        message: "确定要提现吗？",
        beforeClose:function(action, done) {
          if (action == "confirm") {
            console.log("确认按钮");
            if (parseFloat(_that.money) >= parseFloat(_that.minMoney)) {
              if (!openid) {
                _that.getwetchname(); // 获取微信code
              }else{
                _that.applytixian();
              }
            }
          }
          if (action == "cancel") {
            console.log("确认按钮");
          }
          done();
        }
      });
    },
    // 获取微信信息
    getwetchname() {
      let _that = null;
      _that = this;
      let uri = null;
      uri = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + _that.$store.state.wxappid + "&redirect_uri=" + window.location.href + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
      window.location.href = uri;
    },
    // 获取微信信息
    getwxinfo(code) {
      let _that = null;
      _that = this;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(res => {
          console.log(res);
          if (res.data) {
            localStorage.setItem("DK_OPENID", res.data);
          }
          _that.clearCode(); // 清除url中code值
        })
        .catch(err => {
          _that.clearCode(); // 清除url中code值
          console.log(err);
        })
    }, 
    // 提现申请提交到后台
    applytixian() {
      let _that = null;
      _that = this;
      
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");

      console.log(openid, _that.userinfo.openid, '==');

      if (_that.userinfo.openid && openid == _that.userinfo.openid) {

        _that.$toast({
          type: 'loading',
          message: '加载中...',
          overlay: true,
          forbidClick: true,
          duration: 0
        })
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/commission/apply_tixian",
            _that.$qs.stringify({
              money: _that.money,
              openid: openid,
              realname: _that.realname
            })
          )
          .then(response => {
            if (response.data.code == 100000) {
              _that.$router.push({ name: "TixianResult", query: { shareid: _that.shareid, merchid: _that.merchid } });
              _that.load = false;
              _that.$toast.clear();
            }else {
              _that.$toast(response.data.msg);
            }
          })
          .catch(err => {
            console.log(err);
            _that.$toast.clear();
          })
      }else {
        _that.$toast("登陆微信与账号绑定微信不匹配");
      } 
      
    },
    // 查看提现明细
    commissionTixian() {
      // status = 0 // 默认所有
      this.$router.push({ name: "Income", query: { status: 0, shareid: this.shareid, merchid: this.merchid } });
    },
    // 更新用户信息
    setuserinfo() {
      let _that = null;
      _that = this;
      console.log(_that.userinfo, _that.wxinfo, "openid");
      if (_that.userinfo.openid == _that.wxinfo.openid) {
        _that.applytixian(); // 提现申请到后台
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/setinfo",
            _that.$qs.stringify(_that.wxinfo)
          )
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.log(err);
          })
      }else {
        _that.$toast("登陆微信与账号绑定微信不匹配");
        
      }
      _that.clearCode(); // 清除url中code值
      console.log("sss");
    },

    // 清除url中code值
    clearCode() {
      this.$router.replace({ name: "ComissionTixian", query: { status: 0, shareid: this.shareid, merchid: this.merchid } });
    }
  }
}
</script>
<style lang="less">
.comissiontixian {
  width: 100%;
  min-height: 100%;
  background-color: #ededed;
  overflow: hidden;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .tixian_main {
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    padding: 0 0 10px;
    margin: 10px;
    .account_name {
      background-color: #F9F9F9;
      padding-top: 20px;
      color: #333;
      font-weight: bold;
      font-size: 12px;
      .van-cell__value {
        flex: 1;
        text-align: center;
      }
    }
    .van-form {
      padding-top: 10px;
      overflow: hidden;
      .van-cell {
        font-weight: bold;
      }
      .van-field {
        .van-field__label{
          width: 20px;
          margin-right: 0;
          font-size: 12px;
        }
        .van-field__control{
          font-size: 20px;
        }
      }
      .tixian_label{
        font-size: 12px;
        font-weight: normal;
        color: #999;
      }
    }
  }
  .tixianmingxi {
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    padding: 10px 16px;
    margin: 10px;
    .van-cell {
      padding: 0;
      .van-cell__title {
        flex: 1;
        text-align: left;

      }
    }
  }
}
</style>